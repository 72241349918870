import Button from '../../components/button'
import Callout from '../../components/callout'
import Page, { Header } from '../../components/page'
import Seo from '../../components/seo'

const ServiceCatchAll = () => (
  <>
    <Seo title="Services" />
    <Page container>
      <Header
        title="Service not found"
        subtitle="Couldn't find service you're looking for"
      />
      <Button variant="primary" link={{ to: '/services' }}>
        View all services
      </Button>
    </Page>
    <Callout />
  </>
)

export default ServiceCatchAll
